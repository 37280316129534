import Gutter from './Gutter';
import Icon from './Icon';
import Image from './Image';
import { LinkString } from './types';
import React from 'react';
import styles from './ImageLink.module.scss';

export type ImageLinkProps = {
  src: LinkString;
  alt: string;
  title: string;
  href: LinkString;
};

const ImageLink: React.FC<ImageLinkProps> = ({ src, href, title }) => {
  if (href.length === 0) {
    return (
      <div
        className={styles.imageLink}
        style={{ backgroundImage: `url(${src})` }}
      >
        <Image src={src} />
      </div>
    );
  }

  return (
    <a className={styles.imageLink} title={title} href={href}>
      <Image src={src} />

      <div className={styles.iconContainer}>
        <span>Listen</span>

        <Gutter half={true} />

        <Icon
          icon={href.indexOf('spotify') > -1 ? 'spotify' : 'youtube'}
          className={styles.icon}
        />
      </div>
    </a>
  );
};

export default ImageLink;
