import { FC } from "react";
import Albums from "./Albums";
import { FiftyFifty } from "./FiftyFifty";
import Gutter from "./Gutter";
import Logo from "./Logo";
import Merch from "./Merch";
import Preorder from "./Preorder";

import Section from "./Section";
import Social from "./Social";
import { Spotify } from "./Spotify";
import { Footer } from "./Footer";

export const Home: FC = () => {
  return (
    <>
      <Gutter />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Logo />
      </div>

      <Gutter />

      <Section>
        <FiftyFifty>
          <Preorder />

          <Spotify />
        </FiftyFifty>
      </Section>

      <Gutter />

      <Social />

      <Gutter />

      <Merch />

      <Gutter />

      <Albums />

      <Gutter />

      <Footer />
    </>
  );
};
