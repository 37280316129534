import { FC } from "react";
import styles from "./MenuItems.module.scss";
import { Link } from "./Link";

type MenuItemsProps = {
  items: Link[];
};

export const MenuItems: FC<MenuItemsProps> = ({ items }) => {
  return (
    <div className={styles.menuItems}>
      {items.map((item, i) => (
        <a key={i} href={item.href}>
          {item.label}
        </a>
      ))}
    </div>
  );
};
