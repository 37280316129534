import { ReactComponent as BandcampImage } from './images/bandcamp-logo.svg';
import { ReactComponent as CartImage } from './images/shopping-cart.svg';
import { ReactComponent as EmailImage } from './images/email.svg';
import { ReactComponent as FacebookImage } from './images/facebook-circular-logo.svg';
import { ReactComponent as InstagramImage } from './images/instagram.svg';
import { ReactComponent as SpotifyImage } from './images/spotify.svg';
import { ReactComponent as YouTubeImage } from './images/youtube.svg';
import styles from './Icon.module.scss';

export type IconName =
  | 'youtube'
  | 'bandcamp'
  | 'facebook'
  | 'cart'
  | 'spotify'
  | 'email'
  | 'instagram';

const Icon: React.FC<{ icon: IconName; className?: string }> = ({
  icon,
  className
}) => {
  const cls = !!className ? [styles.icon, className].join(' ') : styles.icon;

  switch (icon) {
    case 'facebook':
      return <FacebookImage className={cls} />;
    case 'bandcamp':
      return <BandcampImage className={cls} />;
    case 'youtube':
      return <YouTubeImage className={cls} />;
    case 'cart':
      return <CartImage className={cls} />;
    case 'spotify':
      return <SpotifyImage className={cls} />;
    case 'instagram':
      return <InstagramImage className={cls} />;
    case 'email':
      return <EmailImage className={cls} />;
  }
  return null;
};

export default Icon;
