import sort, { Direction } from './sort';

import { Album } from './types';
import Cta from './Cta';
import Gutter from './Gutter';
import ImageLink from './ImageLink';
import React from 'react';
import Section from './Section';
import Tiles from './Tiles';
import albums from './data/albums.json';
import styles from './Albums.module.scss';

const Albums: React.FC = () => {
  return (
    <Section>
      <h1 className="sectionHeading">Releases</h1>

      <Tiles>
        {sort(albums as Album[])('releaseDate')(Direction.DESC).map((a) => (
          <div key={a.id} className={styles.album}>
            <ImageLink
              src={a.image}
              alt={a.title}
              title="Listen"
              href={a.listen}
            />

            <Gutter half={true} />

            <span>{`${a.releaseDate}: ${a.title}`}</span>

            <Gutter half={true} />

            <Cta text="Buy" link={a.buy} />
          </div>
        ))}
      </Tiles>
    </Section>
  );
};

export default Albums;
