import React from "react";
import logo from "./images/diablerie-logo.svg";
import styles from "./Logo.module.scss";

const Logo: React.FC = () => {
  return (
    <a href="/" className={styles.logo}>
      <img src={logo} alt="Diablerie" />
    </a>
  );
};

export default Logo;
