import Gutter from './Gutter';
import Icon from './Icon';
import { LinkString } from './types';
import React from 'react';
import styles from './Cta.module.scss';

export type CtaProps = {
  text: string;
  link: LinkString;
};

const Cta: React.FC<CtaProps> = ({ text, link }) => {
  if (link.length === 0) {
    return (
      <div className={styles.disabled}>
        <span>out of stock</span>
      </div>
    );
  }

  return (
    <a href={link} className={styles.cta}>
      <span>{text}</span>

      <Gutter half={true} />

      <Icon className={styles.icon} icon="cart" />
    </a>
  );
};

export default Cta;
