import { Formik } from 'formik';
import Gutter from './Gutter';
import Icon from './Icon';
import Image from './Image';
import React from 'react';
import styles from './Preorder.module.scss';

const Preorder: React.FC = () => {
  return (
    <div className={styles.preorder}>
      <div className={styles.image}>
        <Image src="/images/alternate.jpeg" />
      </div>

      <Gutter />

      <Formik
        initialValues={{ EMAIL: '' }}
        validate={(values) => {
          const emailError = !values.EMAIL
            ? 'Required'
            : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.EMAIL)
            ? 'Invalid email address'
            : undefined;
          return emailError ? { EMAIL: emailError } : {};
        }}
        onSubmit={console.log}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid
        }) => (
          <form
            className={styles.form}
            action="https://diablerie.us1.list-manage.com/subscribe/post?u=57b2afaf5bdd5f39f270822cf&amp;id=fa14bbb704"
            method="post"
          >
            <h1>Preorder The Catalyst vol. 2: Alternate</h1>

            <p>
              <strong>Join our mailing list</strong> and you will be the first
              to know when preorders for our upcoming album are open!
            </p>

            <label>
              <span>Please enter your email address: </span>
              {touched.EMAIL && errors.EMAIL && (
                <span className={styles.errors}>{errors.EMAIL}</span>
              )}
            </label>

            <Gutter half={true} />

            <div className={styles.formRow}>
              <input
                type="email"
                name="EMAIL"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.EMAIL}
                placeholder="your@email"
                disabled={isSubmitting}
              />

              <Gutter half={true} />

              <input
                type="hidden"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
              />

              <button
                className={
                  !isValid || values.EMAIL.length === 0
                    ? [styles.button, styles.disabled].join(' ')
                    : styles.button
                }
                type="submit"
                disabled={!isValid}
              >
                <span>Join</span>

                <Gutter half={true} />

                <Icon icon="email" className={styles.icon} />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Preorder;
