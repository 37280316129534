import Icon, { IconName } from "./Icon";

import Gutter from "./Gutter";
import React, { PropsWithChildren } from "react";
import Section from "./Section";
import styles from "./Social.module.scss";
import Tiles from "./Tiles";

const IconHeading: React.FC<
  PropsWithChildren<{
    icon: IconName;
  }>
> = ({ icon, children }) => {
  return (
    <h2 className={styles.iconHeading}>
      <Icon icon={icon} className={styles.icon} />

      <Gutter half={true} />

      <span>{children}</span>
    </h2>
  );
};

const Social: React.FC = () => {
  return (
    <div className={styles.social}>
      <Section>
        <Tiles>
          <a href="https://diablerieband.bandcamp.com/merch">
            <IconHeading icon="bandcamp">Bandcamp</IconHeading>

            <p>
              Buy albums and merch at the official Diablerie Bandcamp page!{" "}
            </p>
          </a>

          <a href="https://www.youtube.com/channel/UC_o-SjytYJKojMIEg_YJB3Q">
            <IconHeading icon="youtube">YouTube</IconHeading>

            <p>
              Watch Diablerie music videos and studio diary updates on our
              YouTube channel!
            </p>
          </a>

          <a href="https://www.facebook.com/Diablerie/">
            <IconHeading icon="facebook">Facebook</IconHeading>

            <p>
              Stay up to date with our studio and tour activity by subscribing
              to our Facebook page!
            </p>
          </a>

          <a href="https://www.instagram.com/diableriemetal/">
            <IconHeading icon="instagram">Instagram</IconHeading>

            <p>
              Stay up to date with our studio and tour activity by subscribing
              to our Instagram page!
            </p>
          </a>
        </Tiles>
      </Section>
    </div>
  );
};

export default Social;
