import { FC, PropsWithChildren } from "react";
import { Menu } from "../Menu";
import { MenuItems } from "../MenuItems";
import Logo from "../Logo";
import Gutter from "../Gutter";
import { Footer } from "../Footer";

export const EPK: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Menu>
        <MenuItems
          items={[
            { label: "Back to public site", href: "/" },
            { label: "Press releases", href: "/epk/press-releases" },
            { label: "Biography", href: "/biography" },
            { label: "Downloads", href: "/downloads" },
          ]}
        />
      </Menu>

      <Gutter />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Logo />
      </div>

      <Gutter />

      <div style={{ maxWidth: "1200px", margin: "0 auto 0 auto" }}>
        {children}
      </div>

      <Gutter />

      <Footer />

      <Gutter />
    </>
  );
};
