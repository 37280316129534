import { FC } from "react";
import { useSanityQuery } from "../data/useSanityQuery";
import { PressReleaseResponse, getPressRelease } from "../data/queries";
import { PortableText } from "@portabletext/react";
import { useParams } from "react-router";
import { EPK } from "./EPK";

export const PressRelease: FC = () => {
  const { slug } = useParams();

  const { data } = useSanityQuery<PressReleaseResponse>(
    getPressRelease(slug ?? "")
  );

  return (
    <EPK>
      {data &&
        data.map((item) => (
          <>
            <h1>{item.title}</h1>

            <PortableText value={item.content} key={item.slug.current} />
          </>
        ))}
    </EPK>
  );
};
