import React from 'react';
import Section from './Section';
import styles from './Credits.module.scss';

const Credits: React.FC = () => {
  return (
    <Section>
      <div className={styles.credits}>
        <span>Icons made by </span>
        <a href="https://www.freepik.com" title="Freepik">
          Freepik
        </a>
        <span> and </span>
        <a
          href="https://www.flaticon.com/authors/pixel-perfect"
          title="Pixel perfect"
        >
          Pixel perfect
        </a>
        <span> from </span>
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
    </Section>
  );
};

export default Credits;
