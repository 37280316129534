import { FC } from "react";
import { Home } from "./Home";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PressReleases } from "./pages/PressReleases";
import { PressRelease } from "./pages/PressRelease";
import { Page } from "./Page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/epk/press-releases",
    element: <PressReleases />,
  },
  {
    path: "/epk/press-releases/:slug",
    element: <PressRelease />,
  },
  {
    path: "/:slug",
    element: <Page />,
  },
]);

const App: FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
