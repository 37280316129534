import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import styles from "./Menu.module.scss";

const ESC_KEY = "Escape";

export const Menu: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = useCallback(() => setOpen(!open), [open]);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (open && e.key === ESC_KEY) {
        toggle();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, toggle]);

  return open ? (
    <div className={styles.menu}>
      <button onClick={toggle}>Close</button>

      <div>{children}</div>
    </div>
  ) : (
    <button className={styles.toggle} onClick={toggle}>
      Menu
    </button>
  );
};
