import React from 'react';
import styles from './Image.module.scss';

const Image: React.FC<{ src: string; className?: string }> = ({
  src,
  className
}) => {
  const cls = !!className ? [className, styles.image].join(' ') : styles.image;

  return <div className={cls} style={{ backgroundImage: `url(${src})` }}></div>;
};

export default Image;
