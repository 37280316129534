import Icon from './Icon';
import styles from './Spotify.module.scss';

export const Spotify = () => (
  <div className={styles.spotify}>
    <a href="https://open.spotify.com/artist/51n2mjNwsIbH9rDrZm31YT?si=pqnhOrXhTXCvb73rvqo0Hw">
      <Icon icon="spotify" />

      <span>Listen to Diablerie on Spotify!</span>
    </a>
  </div>
);
