export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC'
}

const sortByProp = <T, K extends keyof T>({
  prop,
  direction
}: {
  prop: K;
  direction: Direction;
}) => (a: T, b: T) => {
  if (a[prop] > b[prop]) {
    return direction === Direction.ASC ? 1 : -1;
  }

  if (a[prop] < b[prop]) {
    return direction === Direction.ASC ? -1 : 1;
  }

  return 0;
};

const sort = <T, K extends keyof T>(items: T[]) => (prop: K) => (
  direction: Direction
): T[] =>
  [...items].sort(
    sortByProp({
      prop,
      direction
    })
  );

export default sort;
