import { FC } from "react";
import { useSanityQuery } from "../data/useSanityQuery";
import { PressReleasesResponse, listPressReleases } from "../data/queries";
import { EPK } from "./EPK";
import { PortableText } from "@portabletext/react";
import styles from "./PressReleases.module.scss";
import { formatDate } from "../lib/formatDate";

export const PressReleases: FC = () => {
  const { data, builder } =
    useSanityQuery<PressReleasesResponse>(listPressReleases);

  return (
    <EPK>
      <h1>Press releases</h1>

      <div className={styles.pressReleases}>
        {data &&
          data.map((item) => (
            <div className={styles.pressRelease} key={item.slug.current}>
              <a href={`/epk/press-releases/${item.slug.current}`}>
                <img
                  src={builder.image(item.image).width(800).url()}
                  alt={item.title}
                />

                <h1>{item.title}</h1>

                <span>{formatDate(item._updatedAt)}</span>

                <PortableText value={item.ingress} key={item.slug.current} />
              </a>
            </div>
          ))}
      </div>
    </EPK>
  );
};
