export type PressReleasesResponse = {
  slug: {
    current: string;
  };
  title: string;
  image: {
    asset: {
      _ref: string;
    };
  };
  ingress: any[];
  _updatedAt: string;
}[];

export type PressReleaseResponse = {
  content: any[];
  slug: {
    current: string;
  };
  title: string;
}[];

export type PageResponse = {
  content: any[];
  slug: {
    current: string;
  };
  title: string;
}[];

export const listPressReleases =
  '*[_type == "press-release"]{title, slug, image, _updatedAt, ingress}';

export const getPressRelease = (slug: string) =>
  `*[_type == "press-release" && slug.current == "${slug}"]`;

export const getPage = (slug: string) =>
  `*[_type == "page" && slug.current == "${slug}"]`;
