import Cta from './Cta';
import Gutter from './Gutter';
import Image from './Image';
import { Merch as MerchItem } from './types';
import React from 'react';
import Section from './Section';
import Tiles from './Tiles';
import merch from './data/merch.json';
import styles from './Merch.module.scss';

const Merch: React.FC = () => {
  return (
    <Section>
      <div className={styles.merch}>
        <h1 className="sectionHeading">Merch</h1>

        <Tiles>
          {(merch as MerchItem[]).map((m) => (
            <div className={styles.merchItem} key={m.id}>
              <Image src={m.image} />

              <Gutter half={true} />

              <span>{m.title}</span>

              <Gutter half={true} />

              <Cta text="Buy" link={m.buy} />
            </div>
          ))}
        </Tiles>
      </div>
    </Section>
  );
};

export default Merch;
