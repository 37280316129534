import { useEffect, useState } from "react";
import { createClient } from "../pages/createClient";
import imageUrlBuilder from "@sanity/image-url";

type SanityQueryResult<T> = {
  data?: T;
  loading: boolean;
  error?: Error;
  builder: ReturnType<typeof imageUrlBuilder>;
};

export const useSanityQuery = <T>(query: string): SanityQueryResult<T> => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const client = createClient();
  const builder = imageUrlBuilder(client);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const response = await client.fetch(query).catch((e) => setError(e));

      setData(response);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { data, error, loading, builder };
};
